async function importHtmx() {
    const { default: htmx } = await import(/* webpackChunkName: "htmx" */ 'htmx.org/dist/htmx.js');
    return htmx;
}
// Execute async function
importHtmx().then((htmx) => {

    htmx.config.requestClass = 'loading';

    htmx.on('htmx:load', function(event) {
        function initLuminous(options, lightboxLinks) {

            let config = options || {};

            if (lightboxLinks) {
                lightboxLinks.forEach( lightboxLink => {
                    new Luminous(lightboxLink, config);
                });
            }

        }

        let options = {
            caption: (trigger) => trigger.querySelector('img').getAttribute('data-caption')
        }

        let lightboxLinks = document.querySelectorAll(".grad-photo-card");
        if (lightboxLinks) {
            initLuminous(options, lightboxLinks);
        }
    });
    htmx.on('htmx:afterSwap', function(event) {
        console.log('swapped 👍');

        function resetScrollIndicators(scrollPosition) {
            if (scrollPosition == 0) {
                scrollUpIndicator.classList.add('hide');
                scrollDownIndicator.classList.remove('hide');
            }
        }
        function totalHeight(scrollableEl){
            let totalHeight = 0;
            totalHeight = scrollableEl.scrollHeight;
            return totalHeight;
        }

        // find scrollable sections
        const scrollableSection = document.querySelector('.scrollable');
        const scrollableClassNames = document.querySelector('.scrollable-names');
        let scrollableSectionArray = [];
        // find scrollable section indicators
        const scrollUpIndicator = document.querySelector('.scroll-up');
        const scrollDownIndicator = document.querySelector('.scroll-down');
        const nameScrollUpIndicator = document.querySelector('.name-scroll-up');
        const nameScrollDownIndicator = document.querySelector('.name-scroll-down');

        // add scrollable section to array if it exists
        if (scrollableSection) {
            scrollableSectionArray.push(scrollableSection);
        }
        if (scrollableClassNames) {
            scrollableSectionArray.push(scrollableClassNames);
        }

        // if (scrollUpIndicator && scrollDownIndicator) {
        //     let currentScrollPos = 0;
        //     let scrollableTotalHeight = totalHeight();
        //     // call resetScrollIndicators function
        //     // when sprig component swaps new html
        //     resetScrollIndicators(currentScrollPos);
        //
        //     if (scrollableTotalHeight <= scrollableSection.offsetHeight) {
        //         scrollUpIndicator.classList.add('hide');
        //         scrollDownIndicator.classList.add('hide');
        //     }
        //
        //     scrollableSection.addEventListener('scroll', function(){
        //         let parentHeight = totalHeight();
        //         currentScrollPos = scrollableSection.scrollTop;
        //
        //         if (this.offsetHeight + currentScrollPos + 100 >= parentHeight) {
        //             scrollDownIndicator.classList.add('hide');
        //         } else {
        //             scrollDownIndicator.classList.remove('hide');
        //         }
        //         if (currentScrollPos <= 50) {
        //             scrollUpIndicator.classList.add('hide');
        //         } else {
        //             scrollUpIndicator.classList.remove('hide');
        //         }
        //         // console.log(`Position: ${currentScrollPos}`);
        //     });
        // }

        // check to make sure the page is scrollable
        if (scrollableSection || scrollableClassNames) {
            let currentScrollPos = 0;
            let scrollableTotalHeight = totalHeight(scrollableSection);
            // call resetScrollIndicators function
            // when sprig component swaps new html
            resetScrollIndicators(currentScrollPos);

            if (scrollableTotalHeight <= scrollableSection.offsetHeight) {
                scrollUpIndicator.classList.add('hide');
                scrollDownIndicator.classList.add('hide');
            }

            // scroll event listener for each scrollable section
            scrollableSectionArray.forEach((element) => {
                element.addEventListener('scroll', (e) => {
                    let targetEl = e.target;
                    let parentHeight = totalHeight(targetEl);
                    let currentScrollPos = targetEl.scrollTop;

                    // check if target element is the first instance of .scrollable
                    if (e.target == scrollableSection) {
                        if (targetEl.offsetHeight + currentScrollPos + 100 >= parentHeight) {
                            scrollDownIndicator.classList.add('hide');
                        } else {
                            scrollDownIndicator.classList.remove('hide');
                        }

                        if (currentScrollPos <= 50) {
                            scrollUpIndicator.classList.add('hide');
                        } else {
                            scrollUpIndicator.classList.remove('hide');
                        }
                    }

                    // check if the target element is the graduating student names element
                    if (e.target == scrollableClassNames) {
                        if (targetEl.offsetHeight + currentScrollPos >= parentHeight) {
                            nameScrollDownIndicator.classList.add('hide');
                        } else {
                            nameScrollDownIndicator.classList.remove('hide');
                        }

                        if (currentScrollPos <= 10) {
                            nameScrollUpIndicator.classList.add('hide');
                        } else {
                            nameScrollUpIndicator.classList.remove('hide');
                        }
                    }
                });
            });
        }
    });
});